<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Member </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateUser">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="member_email"
                label="Member Email"
                placeholder="Input Member Email"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="member_name"
                label="Member Name"
                placeholder="Input nama lengkap"
              />  
              </CCol>      
            </CRow>            

                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/members">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Member gagal diinput.
    </CModal>
   
  </div>
    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      member_email : "",
      member_email2 : "",
      member_name : "",
      email : "",
      pass : "",
      level : "",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showUser();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updateUser: function(event){
      // Simple POST request with a JSON body using axios
      const user = { 
                          member_email: this.member_email, 
                          member_email2: this.member_email2, 
                          member_name: this.member_name,
                          id_member: this.$route.params.id_member,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"updatemember", user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status===200){
              this.$router.push('/members');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showUser: function(event){
      // Simple POST request with a JSON body using axios
      const pekerjaan = { 
                          kode: this.kode, 
                          nama: this.nama,
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      // let username =window.btoa(this.$route.params.id_user);
      let id =this.$route.params.id_member;

      axios.get(process.env.VUE_APP_BASE_URL+"editmember/"+id)
        .then((response) => {
            console.log(response);
            // alert(response.data);
            // if(response.data=='sukses'){
              // this.$router.push('/User');
              this.member_email=response.data.member_email;
              this.member_email2=response.data.member_email;
              this.member_name=response.data.member_name;

              // alert(response.data);
            // }
            // else{
            //   this.$root.$emit("bv::show::modal", "modalError");
            // }
            
        })
    }
  }
}
</script>